import { createContext } from 'react';
import { noop } from '../utils';

export interface AuthContextValue {
  isAuthorized: boolean;
  isClient: boolean;
  isLoading: boolean;
  signIn(isClient: boolean): void;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthorized: false,
  isClient: true,
  isLoading: true,
  signIn: noop,
  signOut: noop,
});
