import { useQuery } from '@tanstack/react-query';
import plural from 'plural-ru';
import { FC } from 'react';
import { OrderApi } from '../../api';
import { formatDateRu, formatMoney } from '../../utils';
import { OrderItemRow } from '../molecules';
import { OrderItem } from '../../model';

interface Props {
  orderId: number;
}

const groupOrderItems = (items: OrderItem[]) => {
  const groupedItems: { [key: number]: OrderItem & { daytimeAmounts: string[] } } = {};
  items.forEach((item) => {
    const medicineId = item.medicine.id;

    if (groupedItems[medicineId]) {
      groupedItems[medicineId].amount += item.amount;
      groupedItems[medicineId].price += item.price;
      if (item.receipt_medicine) {
        groupedItems[medicineId].daytimeAmounts.push(
          `${item.receipt_medicine.daytime?.value ?? ''} x${item.receipt_medicine.amount}`
        );
      }
    } else {
      groupedItems[medicineId] = {
        ...item,
        daytimeAmounts: item.receipt_medicine
          ? [`${item.receipt_medicine.daytime?.value ?? ''} x${item.receipt_medicine.amount}`]
          : [],
      };
    }
  });
  return Object.values(groupedItems);
};

export const DoctorOrderDetails: FC<Props> = ({ orderId }) => {
  const { data: orderDetails } = useQuery({
    enabled: !!orderId,
    queryFn: () => OrderApi.getById(Number(orderId)),
    queryKey: [OrderApi.KEY, 'getById', orderId],
  });

  if (!orderDetails) {
    return null;
  }

  const groupedItems = groupOrderItems(orderDetails.items);

  return (
    <div className="bg-white rounded-lg">
      <header className="p-[24px]">
        <span className="font-bold text-[24px] leading-[36px]">
          №{orderId}
          <br />
          {orderDetails.status.value} {formatDateRu(orderDetails.status_date, 'dd MMMM, yyyy')}
        </span>
        <span className="mt-[12px] block text-brand-1/50">
          Статус заказа:&nbsp;<span className="text-brand-5 font-bold">{orderDetails.status.value}</span>
        </span>
      </header>

      <div className="bg-brand-1 rounded-lg">
        <header className="flex items-center justify-between py-[16px] px-[24px] text-white">
          <span>Лекарства</span>
          <span>
            Сформировал(а) заказ:&nbsp;
            <span className="text-brand-5 underline">{orderDetails.doctor.fullName}</span>
          </span>
        </header>

        <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
          <table className="table">
            <tbody>
              {groupedItems.map((item, index) => (
                <OrderItemRow key={item.medicine.id} item={item} index={index} />
              ))}
              <tr>
                <td />
                <td>
                  Итого за {groupedItems.length}{' '}
                  {plural(groupedItems.length, 'лекарство', 'лекарства', 'лекарств')}
                </td>
                <td className="text-right text-[20px]">{formatMoney(orderDetails.price)}</td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};