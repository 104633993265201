import { FC, useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AuthApi, CommonApi } from '../api';
import { AuthRoute, Select } from '../components';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useAuthContext } from '../hooks';
import classNames from 'classnames';
import { ClientAuthLayout } from '../layouts';
import { normalizePhoneNumber } from '../utils';



interface RegistrationFormFields {
  name: string;
  midname: string;
  surname: string;
  gender_id: number;
  birthdate: string;
  username: string;
  email: string;
  password: string;
  hospital_id: number;
  code?: string;
}

interface SendEmailParams {
  email: string;
  hospitalId: string;
}

export const ClientSignupPage: FC = () => {
    const { signIn } = useAuthContext();

    const { data: hospitals = [] } = useQuery({
        queryKey: [CommonApi.KEY, 'getHospitals'],
        queryFn: CommonApi.getHospitals,
    });

    const { data: genderList = [] } = useQuery({
        queryKey: [CommonApi.KEY, 'getGenders', 107],
        queryFn: () => CommonApi.getGenders(107),
    });

    const [isCodeSent, setIsCodeSent] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<RegistrationFormFields>();

    const { mutate: sendEmailMutation, isLoading: isSendingEmail } = useMutation({
        mutationFn: async ({ email, hospitalId }: SendEmailParams) =>
        AuthApi.sendEmail(email, hospitalId),
        onSuccess: () => {
        setIsCodeSent(true);
        },
        onError: () => {
        toast.error('Ошибка при отправке кода. Попробуйте снова.');
        },
    });

    const { mutate: registerClientMutation, isLoading: isRegistering } = useMutation({
        mutationFn: (data: RegistrationFormFields) => AuthApi.registerClient(data),
        onSuccess: () => {
            signIn(true);
        },
        onError: () => {
        toast.error('Ошибка при подтверждении кода. Попробуйте снова.');
        },
    });

    const onSubmit: SubmitHandler<RegistrationFormFields> = useCallback(
        (data) => {
        const normalizedPhone = normalizePhoneNumber(data.username);
        if (isCodeSent) {
            registerClientMutation({...data, username: normalizedPhone});
        } else {
            sendEmailMutation({
            email: data.email,
            hospitalId: data.hospital_id.toString(),
            });
        }
        },
        [isCodeSent, registerClientMutation, sendEmailMutation]
    );

    return (
        <AuthRoute>
          <ClientAuthLayout>
          <form
              onSubmit={handleSubmit(onSubmit)}
              className="mx-auto w-full max-w-[360px] grid gap-[16px]"
          >
              <h1 className="mb-[36px] text-center text-[30px] leading-[36px] font-medium">
              Регистрация
              </h1>

              <input
                  {...register('name', { required: true })}
                  className={classNames('input', errors.name && 'input--invalid')}
                  placeholder="Имя"
                  type="text"
                />
          
                <input
                  {...register('surname', { required: true })}
                  className={classNames('input', errors.surname && 'input--invalid')}
                  placeholder="Фамилия"
                  type="text"
                />
      

                <input
                  {...register('midname', { required: true })}
                  className={classNames('input', errors.midname && 'input--invalid')}
                  placeholder="Отчество"
                  type="text"
                />
      

                <Controller
                  control={control}
                  name="gender_id"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Select
                      error={fieldState.error}
                      onChange={field.onChange}
                      options={genderList.map((gender) => ({
                        label: gender.value,
                        value: gender.id,
                      }))}
                      placeholder="Пол"
                      value={field.value}
                    />
                  )}
                />
          
                <Controller
                  control={control}
                  name="username"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      {...field}
                      alwaysShowMask
                      className={classNames('input', fieldState.invalid && 'input--invalid')}
                      mask="+7 999 999 99 99"
                      maskPlaceholder={null}
                      placeholder="Номер телефона"
                      type="tel"
                      value={field.value ?? ''}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="birthdate"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      {...field}
                      alwaysShowMask
                      className={classNames('input', fieldState.invalid && 'input--invalid')}
                      mask="9999-99-99"
                      maskPlaceholder={null}
                      placeholder="Дата рождения (ГГГГ-ММ-ДД)"
                      type="tel"
                      value={field.value ?? ''}
                    />
                  )}
                />
          
                <input
                  {...register('email', {
                    required: true,
                    pattern: /^\S+@\S+$/,
                  })}
                  className={classNames('input', errors.email && 'input--invalid')}
                  placeholder="Email"
                  type="email"
                />
          


                <input
                  {...register('password', { required: true })}
                  className={classNames('input', errors.password && 'input--invalid')}
                  placeholder="Пароль"
                  type="password"
                />

                <Controller
                control={control}
                name="hospital_id"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Select
                    error={fieldState.error}
                    onChange={field.onChange}
                    options={hospitals.map((hospital) => ({
                      label: hospital.name,
                      value: hospital.id,
                    }))}
                    placeholder="Госпиталь"
                    value={field.value}
                  />
                )}
                />
                {isCodeSent && (
                  <input
                    {...register('code', { required: true, maxLength: 4 })}
                    className={classNames('input', errors.code && 'input--invalid')}
                    placeholder="Введите код"
                    type="text"
                    maxLength={4}
                  />
                )}

              <button
              className="button button--block button--primary"
              type="submit"
              disabled={isSendingEmail || isRegistering}
              >
              {isSendingEmail || isRegistering
                  ? 'Загрузка...'
                  : isCodeSent
                  ? 'Завершить регистрацию'
                  : 'Отправить код'}
              </button>
          </form>
          </ClientAuthLayout>
        </AuthRoute>
    );
};
