import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContextProvider } from './components';
import { RoutePath } from './config';
import { useAuthContext } from './hooks';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  ClientCalendarPage,
  ClientLoginPage,
  ClientSignupPage,
  ClientMedicinePage,
  ClientOrderHistoryPage,
  ClientSettingsPage,
  DoctorCreateOrderPage,
  DoctorCreatePatientPage,
  DoctorCreateAddressPage,
  DoctorLoginPage,
  DoctorMedicinePage,
  DoctorOrderHistoryItemPage,
  DoctorOrderHistoryPage,
  DoctorPatientDetailsPage,
  DoctorEditPatientPage,
  DoctorPatientOrderDetailsPage,
  DoctorPatientsPage,
} from './pages';

export const queryClient = new QueryClient();

export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContextProvider>
          <Pages />
        </AuthContextProvider>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
};

const Pages: FC = () => {
  const { isAuthorized, isClient, isLoading } = useAuthContext();
  if (isLoading) {
    return <div>Loading...</div>; 
  }

  return (
    <Routes>
      <Route path={RoutePath.ClientLogin} element={<ClientLoginPage />} />
      <Route path={RoutePath.DoctorLogin} element={<DoctorLoginPage />} />
      <Route path={RoutePath.ClientRegister} element={<ClientSignupPage />} />     


      {isAuthorized && <Route path={RoutePath.ClientMedicine} element={<ClientMedicinePage />} />}
      {isAuthorized && <Route path={RoutePath.ClientCalendar} element={<ClientCalendarPage />} />}
      {isAuthorized && <Route path={RoutePath.ClientOrderHistory} element={<ClientOrderHistoryPage />} />}
      {isAuthorized && <Route path={RoutePath.ClientSettings} element={<ClientSettingsPage />} />}

      {isAuthorized && <Route path={RoutePath.DoctorCreateOrder} element={<DoctorCreateOrderPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorPatients} element={<DoctorPatientsPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorCreatePatient} element={<DoctorCreatePatientPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorCreateAddress} element={<DoctorCreateAddressPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorPatientDetails} element={<DoctorPatientDetailsPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorEditPatient} element={<DoctorEditPatientPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorPatientOrderDetails} element={<DoctorPatientOrderDetailsPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorMedicine} element={<DoctorMedicinePage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorOrderHistory} element={<DoctorOrderHistoryPage />} />}
      {isAuthorized && <Route path={RoutePath.DoctorOrderHistoryItem} element={<DoctorOrderHistoryItemPage />} />}

      <Route path="*" element={<Navigate replace to={ isClient ? RoutePath.ClientLogin : RoutePath.DoctorLogin} />} />
    </Routes>
  );
};
