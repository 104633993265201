import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RouteProps, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddressResponse } from '../model/Address';
import { PeopleApi } from '../api';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { PrivateRoute } from '../components';
import { AddressAutofill } from '../components/molecules/AddressAutofill';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';


export interface AddressForm {
  address: AddressResponse | null; 
  address_text: string;
  index: string;
  country_name: string;
  region_name: string;
  city_name: string;
  street_name: string;
  house: string;
  flat: string;
  people_main_id: Number;
}


export const DoctorCreateAddressPage: FC<RouteProps> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { patientId } = useParams<{ patientId: string }>();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<AddressForm>();

  const { mutate: createAddress } = useMutation({
    mutationFn: PeopleApi.createPatientsAddress,
    onSuccess() {

      navigate(RoutePath.DoctorCreateOrder);
    },
    onError(error) {
      console.error(error);
      toast.error('Ошибка! Проверьте данные');
    },
  });

  const onSubmit: SubmitHandler<AddressForm> = useCallback(
    async (data) => {
      createAddress({ ...data, people_main_id: Number(patientId) });
    },
    [createAddress, patientId],
  );
  

  const onChangeHandler = (address: AddressResponse) => {
    setValue('address_text', address.result);
    setValue('index', address.postal_code || '');
    setValue('country_name', address.country || '');
    setValue('region_name', address.region || '');
    setValue('city_name', address.city || '');
    setValue('street_name', address.street || '');
    setValue('house', address.house || '');
    setValue('flat', address.flat || '');
  };


  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="grid grid-cols-2">
          <div className="bg-brand-6 rounded-lg">
            <header className="p-2">
              <button
                className="flex items-center gap-[6px] text-[20px] font-bold"
                onClick={() => navigate(-1)}
                type="button"
              >
                <BackIcon />
                Вернуться назад
              </button>
            </header>
            <form className="grid gap-1 p-2" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="address"
              rules={{ required: true }}
              render={({ field }) => (
                <AddressAutofill
                  onChange={(address: AddressResponse) => {
                    field.onChange(address);
                    setValue('address_text', address.result || '');
                    setValue('index', address.postal_code || '');
                    setValue('country_name', address.country || '');
                    setValue('region_name', address.region || '');
                    setValue('city_name', address.city || '');
                    setValue('street_name', address.street || '');
                    setValue('house', address.house || '');
                    setValue('flat', address.flat || '');
                  }}
                  value={field.value || null}
                />
              )}
            />
              <input {...register('index')} className="input" placeholder="Почтовый индекс" />
              <input {...register('country_name')} className="input" placeholder="Страна" />
              <input {...register('region_name')} className="input" placeholder="Регион" />
              <input {...register('city_name')} className="input" placeholder="Город" />
              <input {...register('street_name')} className="input" placeholder="Улица" />
              <input {...register('house')} className="input" placeholder="Дом" />
              <input {...register('flat')} className="input" placeholder="Квартира" />
              <button className="mt-2 button button--block button--primary" type="submit">
                Добавить адрес
              </button>
            </form>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
