import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { FC, PropsWithChildren, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../api';
import { RoutePath } from '../../config';
import { AuthContext, ApiResponse } from '../../model';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isClient, setIsClient] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response: ApiResponse<any> = await AuthApi.checkAuth(); 
        console.log(response.data);
        if (response.status === 'success') {
          setAuthorized(true);
          setIsClient(response.data === 'client'); 
          setIsLoading(false)
          const lastPath = sessionStorage.getItem('lastPath');
          if (lastPath) {
            navigate(lastPath); 
            sessionStorage.removeItem('lastPath'); 
          }
         } else {
          setAuthorized(false);
          setIsLoading(false);
          }
      } catch (error) {
        setAuthorized(false);
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);


  const signIn = useCallback((isClient: boolean) => {
    setAuthorized(true);
    setIsClient(isClient);
    console.log(isClient)
  }, []);

  const signOut = useCallback(async () => {
    await AuthApi.logOut();
    setAuthorized(false);
    console.log(isClient)
    navigate(isClient ? RoutePath.ClientLogin : RoutePath.DoctorLogin);
    queryClient.clear();
  }, [navigate, isClient, queryClient]);

  return <AuthContext.Provider value={{ isAuthorized: authorized, isClient, isLoading, signIn, signOut }}>{children}</AuthContext.Provider>;
};
