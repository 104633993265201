import type { FC } from 'react';
import { OrderItem } from '../../model/OrderItem';
import { formatMoney } from '../../utils';

interface Props {
  index: number;
  item: OrderItem & { daytimeAmounts: string[] };
}

export const OrderItemRow: FC<Props> = ({ index, item }) => {
  const { amount, medicine, price, daytimeAmounts } = item;

  return (
    <tr>
      <td className="text-brand-1/30 border-none">{index + 1}</td>
      <td>
        <span className="leading-normal">
          <strong>{medicine.name}</strong>&nbsp;{amount}шт
        </span>
        <span className="block text-brand-1/30 text-[14px] leading-[20px]">
        {daytimeAmounts.length > 0 && (
          <span className="block text-brand-1/30 text-[14px] leading-[20px]">
            {daytimeAmounts.join(' • ')}
          </span>
        )}
        </span>
      </td>
      <td className="text-right">{formatMoney(price)}</td>
      <td />
    </tr>
  );
};