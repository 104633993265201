import axios from 'axios';
import { ApiResponse } from '../model';

interface DoctorAuthParams {
  hospital_id: number;
  password: string;
  username: string;
}

export interface PatientRegistration {
  name: string;
  midname: string;
  surname: string;
  gender_id: number;
  birthdate: string;
  username: string;
  email: string;
  password: string;
  hospital_id: number;
  code?: string;
}

export class AuthApi {
  static async authorizeDoctor(params: DoctorAuthParams): Promise<any> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/doctor/login', params);

    return data;
  }

  static async sendEmail(email: string, hospital_id:  string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/client/send_email', { email, hospital_id });
    return data;
  }

  static async registerClient(params: PatientRegistration): Promise<any> {
    const { data } = await axios.post<ApiResponse>('/api/v1/client/register', params);
  
    return data;
  }
  

  static async sendCode(phone: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/send_code', { phone });

    return data;
  }

  static async validateCode(phone: string, code: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/validate_code', { code, phone });

    return data;
  }

  static async logOut(): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/logout');

    return data;
  }
  static async checkAuth(): Promise<ApiResponse> {
    const { data } = await axios.get<ApiResponse>('/api/v1/auth/doctor/check');

    return data;
  }
}
