import { Combobox, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { FC, Fragment, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { CommonApi, PeopleApi } from "../../api";
import { AddressResponse } from "../../model/Address";

interface Props {
  className?: string;
  error?: FieldError;
  onChange(newValue: AddressResponse): void;
  value: AddressResponse | null;
}

export const AddressAutofill: FC<Props> = ({ className, error, onChange, value }) => {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 1000);

    return () => clearTimeout(handler);
  }, [query]);

  const {
    data: addressList = { total: 0, items: [] },
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["address", "search", debouncedQuery],
    queryFn: () => PeopleApi.normalizeAddress(debouncedQuery),
    enabled: !!debouncedQuery,
  });

  return (
    <Combobox value={value} onChange={onChange}>
      <div className={classNames("relative", className)}>
        <Combobox.Input
          className={classNames("input input--block bg-white text-left", {
            "input--invalid": Boolean(error),
          })}
          displayValue={(address: AddressResponse) => (address ? address.result : "")}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Введите адрес доставки"
        />
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-[300px] w-full overflow-auto rounded-md bg-white shadow-lg border border-brand-2">
            {isLoading && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Загрузка...
              </Combobox.Option>
            )}
            {isSuccess && addressList.total === 0 && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Ничего не найдено
              </Combobox.Option>
            )}
            {addressList?.items?.map((address) => (
              <Combobox.Option key={address.source} className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value={address}>
                {({ selected }) => (
                  <>
                    <span className={classNames("block truncate", { "font-bold": selected })}>{address.result}</span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
