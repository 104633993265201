import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { RouteProps, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommonApi, PatientDTO, PeopleApi } from '../api';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { PrivateRoute, Select } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';
import { normalizePhoneNumber } from '../utils';
import format from 'date-fns/format';

export const DoctorEditPatientPage: FC<RouteProps> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { patientId } = useParams<{ patientId: string }>();

  const { data: patient, isLoading } = useQuery({
    queryKey: [PeopleApi.KEY, 'getPatientDetails', patientId],
    queryFn: () => PeopleApi.getPatientDetails(patientId!),
    enabled: !!patientId,
  });

  const { data: genderList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 107],
    queryFn: () => CommonApi.getReference(107),
  });

  const { mutate: updatePatient } = useMutation({
    mutationFn: (updatedData: PatientDTO) => PeopleApi.updatePatient(patientId!, updatedData),
    onSuccess() {
      queryClient.refetchQueries([PeopleApi.KEY, 'getPatientDetails', patientId]);
      navigate(-1);
    },
    onError(error) {
      console.error(error);
      toast.error('Ошибка! Проверьте данные');
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<PatientDTO>({
    defaultValues: patient
      ? {
          ...patient,
          username: patient.phone,
          email: patient.contacts.find(contact => contact.contact_type.id === 19)?.value || '',
          birthdate: format(new Date(patient.birthdate), 'yyyy-MM-dd'),
        }
      : {},
  });

  const onSubmit: SubmitHandler<PatientDTO> = useCallback(
    async (data) => {
      const updatedContacts = data.contacts.map((contact) => {
        if (contact.contact_type.id === 18) { 
          return { ...contact, value: normalizePhoneNumber(data.username) };
        }
        if (contact.contact_type.id === 19) {
          return { ...contact, value: data.email };
        }
        return contact;
      });
      console.log('Данные перед отправкой на сервер:', updatedContacts);
      updatePatient({
        ...data,
        username: normalizePhoneNumber(data.username),
        birthdate: format(new Date(data.birthdate), 'yyyy-MM-dd'),
        contacts: updatedContacts
      });
    },
    [updatePatient],
  );

  if (isLoading || !patientId) return <div>Загрузка...</div>;

  return (
    <PrivateRoute>
      <DoctorInnerPageLayout>
        <div className="grid grid-cols-2">
          <div className="bg-brand-6 rounded-lg">
            <header className="p-2">
              <button
                className="flex items-center gap-[6px] text-[20px] font-bold"
                onClick={() => navigate(-1)}
                type="button"
              >
                <BackIcon />
                Вернуться назад
              </button>
            </header>
            <form className="grid gap-1 p-2" onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register('name', { required: true })}
                className={classNames('input', errors.name && 'input--invalid')}
                placeholder="Имя"
                type="text"
              />
              <input
                {...register('surname', { required: true })}
                className={classNames('input', errors.surname && 'input--invalid')}
                placeholder="Фамилия"
                type="text"
              />
              <input
                {...register('midname', { required: true })}
                className={classNames('input', errors.midname && 'input--invalid')}
                placeholder="Отчество"
                type="text"
              />
              <Controller
                control={control}
                name="gender_id"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Select
                    error={fieldState.error}
                    onChange={field.onChange}
                    options={genderList.map((gender) => ({
                      label: gender.value,
                      value: gender.id,
                    }))}
                    placeholder="Пол"
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="username"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <InputMask
                    {...field}
                    alwaysShowMask
                    className={classNames('input', fieldState.invalid && 'input--invalid')}
                    mask="+7 999 999 99 99"
                    maskPlaceholder={null}
                    type="tel"
                  />
                )}
              />
              <input
                {...register('email', {
                  required: 'Электронная почта обязательна',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Неверный формат электронной почты',
                  },
                })}
                className={classNames('input', errors.email && 'input--invalid')}
                placeholder="Электронная почта"
                type="email"
              />
              <Controller
                control={control}
                name="birthdate"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <InputMask
                    {...field}
                    alwaysShowMask
                    className={classNames('input', fieldState.invalid && 'input--invalid')}
                    mask="9999-99-99"
                    maskPlaceholder={null}
                    placeholder="Дата Рождения (ГГГГ-ММ-ДД)"
                    type="tel"
                    value={field.value || ''} 
                  />
                )}
              />
              <button className="mt-2 button button--block button--primary" type="submit">
                Сохранить изменения
              </button>
            </form>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};