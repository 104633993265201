import axios from 'axios';
import { CollectionApiResponse } from './../model/CollectionApiResponse';
import { ApiResponse, type Hospital, type RefHeader, type ReferenceItem } from '../model';
import type { AddressResponse } from '../model/Address';


export class CommonApi {
  static readonly KEY = 'common';

  static async getHospitals(): Promise<Hospital[]> {
    const { data } = await axios.get<CollectionApiResponse<Hospital>>('/api/v1/public/hospitals');

    return data.items;
  }

  static async getGenders(ref_header_id: number): Promise<ReferenceItem[]> {
    const { data } = await axios.get<CollectionApiResponse<ReferenceItem>>('/api/v1/public/genders',
      {
        params: {
          ref_header_id,
        },
      });
    return data.items;
  }

  static async getRefHeaders(): Promise<RefHeader[]> {
    const { data } = await axios.get<CollectionApiResponse<RefHeader>>('/api/v1/private/ref_header');

    return data.items;
  }

  static async getReference(ref_header_id: number): Promise<ReferenceItem[]> {
    const { data } = await axios.get<CollectionApiResponse<ReferenceItem>>('/api/v1/private/reference', {
      params: {
        ref_header_id,
      },
    });

    return data.items;
  }
}
