import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { ApiResponse, Receipt } from '../model';
import { CollectionApiResponse } from './../model/CollectionApiResponse';
import { AddressResponse } from '../model/Address';

interface CreateReceiptParams {
  patient_id: number;
  address: number;
  description: string;
  receipt_items: Array<{
    total_amount: number,
    amount: number;
    duration?: number;
    medicine_id: number;
    frequency_id: number;
    eating_id?: number;
    daytime_id: number;
  }>;
}

interface GetReceiptParams {
  date_from?: string;
  date_to?: string;
  people_main_id?: number;
}

interface CreateReceiptResponse {
  receipt_id: number;
}

export class ReceiptApi {
  static readonly KEY = 'receipt';

  static async create(body?: CreateReceiptParams): Promise<CreateReceiptResponse> {
    const { data } = await axios.post<ApiResponse<CreateReceiptResponse>>('/api/v1/receipt', body);

    return data.data;
  }

  static async get(params?: GetReceiptParams): Promise<Receipt[]> {
    const { data } = await axios.get<CollectionApiResponse<Receipt>>('/api/v1/client/receipt', {
      params,
    });

    return plainToInstance(Receipt, data.items);
  }

  static async getById(id: number): Promise<Receipt> {
    const { data } = await axios.get<ApiResponse<Receipt>>(`/api/v1/client/receipt/${id}`);

    return plainToInstance(Receipt, data.data);
  }
}
